'use client'
import { setCookieSearch } from '@/app/_server/SearchCookie'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { useEffect } from 'react'

type Props = {

}

const Links = ({  }: Props) => {

    const pathname = usePathname()
    const params = useParams()
    const router = useRouter();

    const handleClick = async (url: string) => {
        router.push(url)
    }

    useEffect(() => {
        
        async function setCookie() {
            await setCookieSearch('')
        }
        if (!Boolean(params.slug && pathname.split('/')[1] === 'ventes')) {
            setCookie()
        }
    }, [pathname, params.slug])
   
    
    return (
        <>
            <div
                className={`text-sm font-bold flex items-center`}
            >
                <button 
                    className={`w-full ${pathname === '/biens-en-vente' ? 'bg-red-600 hover:bg-red-700 text-white' : 'bg-white hover:bg-zinc-500 text-zinc-700 hover:text-white'} duration-700 p-2 rounded-md`}
                    onClick={() => handleClick('/biens-en-vente')}
                >
                    <span>Biens en vente</span>
                </button>
            </div>
            <div
                className={`text-sm font-bold flex items-center`}
            >
                <button 
                    className={`w-full ${pathname === '/resultats' ? 'bg-red-600 hover:bg-red-700 text-white' : 'bg-white hover:bg-zinc-500 text-zinc-700 hover:text-white'} duration-700 p-2 rounded-md`}
                    onClick={() => handleClick('/resultats')}
                >
                    <span>Résultats des ventes</span>
                </button>
            </div>
            <div
                className={`text-sm font-bold flex items-center`}
            >
                <button 
                    className={`w-full ${pathname === '/calendrier-des-ventes' ? 'bg-red-600 hover:bg-red-700 text-white' : 'bg-white hover:bg-zinc-500 text-zinc-700 hover:text-white'} duration-700 p-2 rounded-md`}
                    onClick={() => handleClick('/calendrier-des-ventes')}
                >
                    <span>Calendrier des ventes</span>
                </button>
            </div>
            <div
                className={`text-sm font-bold flex items-center`}
            >
                <button 
                    className={`w-full ${pathname === '/blog' ? 'bg-red-600 hover:bg-red-700 text-white' : 'bg-white hover:bg-zinc-500 text-zinc-700 hover:text-white'} duration-700 p-2 rounded-md`}
                    onClick={() => handleClick('/blog')}
                >
                    <span>Blog</span>
                </button>
            </div>
            <div
                className={`text-sm font-bold flex items-center`}
            >
                <button 
                    className={`w-full ${pathname === '/archives' ? 'bg-red-600 hover:bg-red-700 text-white' : 'bg-white hover:bg-zinc-500 text-zinc-700 hover:text-white'} duration-700 p-2 rounded-md`}
                    onClick={() => handleClick('/archives')}
                >
                    <span>Archives</span>
                </button>
            </div>
            <div
                className={`text-sm font-bold flex items-center`}
            >
                <button
                    className={`w-full text-white mt-3 mb-1 lg:mt-0 lg:mb-0`}
                    onClick={() => handleClick('/faq')}
                >
                    <span className='bg-[#bf210d] hover:bg-red-800 p-[10px] rounded-[20px] duration-700'>Une question ?</span>
                </button>
            </div>
        </>
    )
}

export default Links